export const homeAbout = [
  {
    id: 1,
    cover: "https://img.icons8.com/dotty/80/000000/storytelling.png",
    title: "Usluge",
    desc: "Nudimo online nastavu kroz grupne i individualne časove, prilagođene potrebama svakog učenika.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/diploma.png",
    title: "Edukacija",
desc: "Svaki nivo edukacije od A1 do C2 podrazumeva da dobijate besplatno knjigu, radnu svesku i rečnik koji prate vaše učenje.",
  },
  {
    id: 1,
    cover: "https://img.icons8.com/ios/80/000000/athlete.png",
    title: "Mogućnosti",
    desc: "Od nivoa C1, moguće je pridružiti se nekim od prevodilačkih timova i steći praksu u prevodilačkim uslugama."
    
  },
]

export const online = [
  {
    cover: "./images/courses/online/o1.png",
    hoverCover: "./images/courses/online/o1.1.png",
    courseName: "UI/UX Design Courses",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o2.png",
    hoverCover: "./images/courses/online/o2.1.png",
    courseName: "Art & Design",
    course: "25 Courses",
  },
  {
    cover: "./images/courses/online/o3.png",
    hoverCover: "./images/courses/online/o3.1.png",
    courseName: "Computer Science",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o4.png",
    hoverCover: "./images/courses/online/o4.1.png",
    courseName: "History & Archeologic",
    course: "15 Courses",
  },
  {
    cover: "./images/courses/online/o5.png",
    hoverCover: "./images/courses/online/o5.1.png",
    courseName: "Software Engineering",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o6.png",
    hoverCover: "./images/courses/online/o6.1.png",
    courseName: "Information Software",
    course: "60 Courses",
  },
  {
    cover: "./images/courses/online/o7.png",
    hoverCover: "./images/courses/online/o7.1.png",
    courseName: "Health & Fitness",
    course: "10 Courses",
  },
  {
    cover: "./images/courses/online/o8.png",
    hoverCover: "./images/courses/online/o8.1.png",
    courseName: "Marketing",
    course: "30 Courses",
  },
  {
    cover: "./images/courses/online/o9.png",
    hoverCover: "./images/courses/online/o9.1.png",
    courseName: "Graphic Design",
    course: "80 Courses",
  },
  {
    cover: "./images/courses/online/o10.png",
    hoverCover: "./images/courses/online/o10.1.png",
    courseName: "Music",
    course: "120 Courses",
  },
  {
    cover: "./images/courses/online/o11.png",
    hoverCover: "./images/courses/online/o11.1.png",
    courseName: "Business Administration",
    course: "17 Courses",
  },
  {
    cover: "./images/courses/online/o12.png",
    hoverCover: "./images/courses/online/o12.1.png",
    courseName: "Web Management",
    course: "17 Courses",
  },
]
export const team = [
  {
    cover: "./images/team/t1.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t2.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t3.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t4.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t5.webp",
    name: "Ph.D Adrian Molises",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t6.webp",
    name: "Ph.D Arthur MaGregor",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t7.webp",
    name: "Ph.D Anna Hanzen",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
  {
    cover: "./images/team/t8.webp",
    name: "Ph.D Brian Wooden",
    work: "DEVELOPER AND LEAD INSTRUCTOR",
  },
]
export const price = [
  {
    name: "IME PREZIME",
    price: "Subheader",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "IME PREZIME",
    price: "Subheader",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "IME PREZIME",
    price: "Subheader",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
  {
    name: "IME PREZIME",
    price: "Subheader",
    desc: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts.",
  },
]
export const faq = [
  {
    title: "How to Enroll This Online Courses?",
    desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Where It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How to Enroll This Online Courses?",
    desc: "This is the first item's accordion body. It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "Where It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
  {
    title: "How It is hidden by default, until the collapse?",
    desc: "It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. It's also worth noting that just about any HTML can go within the .accordion-body, though the transition does limit overflow.",
  },
]
export const blog = [
  {
    id: 1,
    type: "admin",
    date: "JAN. 18, 2021",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b1.webp",
  },
  {
    id: 2,
    type: "admin",
    date: "API. 25, 2022",
    com: "5 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b2.webp",
  },
  {
    id: 3,
    type: "user",
    date: "MAY. 15, 2022",
    com: "10 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b3.webp",
  },
  {
    id: 4,
    type: "admin",
    date: "JAN. 02, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b4.webp",
  },
  {
    id: 5,
    type: "admin",
    date: "DEC. 14, 2022",
    com: "3 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b5.webp",
  },
  {
    id: 6,
    type: "user",
    date: "JAN. 18, 2021",
    com: "12 COMMENTS ",
    title: "Build your Dream Software & Engineering Career",
    desc: "A small river named Duden flows by their place and supplies it with the necessary regelialia.",
    cover: "../images/blog/b6.webp",
  },
]
export const testimonal = [
  {
    id: 1,
    name: "NASTAVA",
    post: "GRUPNA I INDIVIDUALNA",
    desc1: `Obe nastave se održavaju putem besplatne Zoom aplikacije. Trajanje časa je do 75 minuta. Fokusiraju se na gramatičku edukaciju i pravopis, uz konverzacijske vežbe. Polaznici dobijaju video snimak časa za ponavljanje, a obezbeđeni su dodatni materijali.`,
    desc2: `Grupna nastava se održava u malim grupama do 7 polaznika i časovi se održavaju jednom nedeljno, s fokusom na interakciju među polaznicima i zajedničkom učenju.`,
    desc3: `Individualna nastava je prilagođena potrebama svakog učenika, a termini se usklađuju prema željama učenika, što omogućava personalizovan pristup i brži napredak.`,
    cover: "./images/testo/t1.webp",
  },
  {
    id: 2,
    name: "NIVOI EDUKACIJE",
    post: "EDUKACIJA SE SPROVODI NA NIVOIMA OD A1 DO C2.",
    desc1: `Edukacija se sprovodi na sledećim nivoima: A1, A2, B1, B2, C1 i C2. Na A1 nivou polaznici uče osnove jezika, uključujući jednostavne fraze i svakodnevne izraze. Na A2 nivou, polaznici razvijaju osnovno razumevanje jezika i koriste ga u poznatim situacijama, uz proširenje rečnika.`,
    desc2: `Srednji nivoi B1 i B2 omogućavaju polaznicima da komuniciraju u različitim situacijama. Na B1 nivou, učenici izražavaju svoje misli i ideje, dok B2 nivo pruža razumevanje kompleksnih tekstova i sposobnost diskutovanja o raznim temama.`,
    desc3: `Na veoma naprednim nivoima, C1 i C2, polaznici stiču sposobnost fluidnog korišćenja jezika u društvenim, akademskim i profesionalnim kontekstima. C1 nivo omogućava naprednu komunikaciju, dok C2 predstavlja majstorski nivo sa savršenim razumevanjem jezika, uključujući idiomatske izraze i kompleksne strukture.`,
    cover: "./images/testo/t2.webp",
  },
  {
    id: 3,
    name: "DODATNO",
    post: "DODATNE INFORMACIJE",
    desc1: `Nudimo besplatan pristup kanalu "Italijanski za sve", gde možete preuzeti dodatne materijale za rad i vežbanje. Takođe, pružamo besplatnu nastavu za sve koji uče jezik iz ličnih razloga ili radi lečenja bliskih članova porodice.`,
    desc2: `Naš cilj je da edukacija o jeziku stigne do što većeg broja ljudi. Ponosimo se dugogodišnjim iskustvom u edukaciji i pedagogiji, što nam omogućava da pružimo kvalitetnu i pristupačnu nastavu.`,
    desc3: `Imamo mnogo zadovoljnih klijenata kojima smo pomogli tokom višegodišnjeg rada, a njihovo zadovoljstvo je naš najveći uspeh.`,
    cover: "./images/testo/t3.webp",
  },
]
