import React from "react";
import { blog } from "../../../dummydata";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <div id="footer" className='legal'>
        <div className='social-icons'>
          <a href="https://www.instagram.com/italijanskijezikzasve/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://www.tiktok.com/@italijanski_za_sve" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-tiktok icon"></i>
          </a>
          <a href="tel:+381631201161">
            <i className="fas fa-phone"></i>
          </a>
          <a href="mailto:📧italijanskizasve@gmail.com">
            <i className="fas fa-envelope"></i>
          </a>
        </div>
        <p>
          © 2024 italijanskizasve.com All rights reserved
        </p>
      </div>
    </>
  );
}

export default Footer;
