import React from "react"
import "./head.css"

const Head = () => {
  return (
    <>
      <section className='head'>
        <div className='container flexSB'>
          <div className='logo'>
            <h1>ITALIJANSKI ZA SVE</h1>
            <span>ONLINE EDUCATION & LEARNING</span>
          </div>

          <div className='social'>
          <a href="https://www.instagram.com/italijanskijezikzasve/" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-instagram icon"></i>
          </a>
          <a href="https://www.tiktok.com/@italijanski_za_sve" target="_blank" rel="noopener noreferrer">
            <i className="fab fa-tiktok icon"></i>
          </a>
          <a href="tel:+381631201161">
            <i className="fas fa-phone icon"></i>
          </a>
          <a href="mailto:📧italijanskizasve@gmail.com">
            <i className="fas fa-envelope icon"></i>
          </a>
          </div>
        </div>
      </section>
    </>
  )
}

export default Head
